<template>
  <div class="outer-page">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="500"
      @on-visible-change="modalChange"
    >
      <Form ref="form" :model="form" :label-width="130" :rules="rules">
        <FormItem label="施用基地" prop="farmId">
          <Select v-model="form.farmId" clearable style="width: 260px">
            <Option
              v-for="(item, index) in farms"
              :key="index"
              :value="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="施用地块" prop="sceneId">
          <Select v-model="form.sceneId" clearable style="width: 260px">
            <Option
              v-for="(item, index) in blocks"
              :key="index"
              :value="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="施用量" prop="amount">
          <Input v-model="form.amount" clearable style="width: 260px"></Input>
        </FormItem>
        <FormItem label="产品分类" prop="productIds">
          <Select v-model="form.productIds" clearable style="width: 260px">
            <Option
              v-for="(item, index) in category"
              :key="index"
              :value="item.id + '/' + item.name"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="施用时间" prop="applyTime">
          <DatePicker
            type="date"
            placeholder="请选择施用时间"
            style="width: 260px"
            v-model="form.applyTime"
          ></DatePicker>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CU from "@/common/util";
import moment from "moment";
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "施用基地",
            key: "farmName",
          },
          {
            title: "施用地块",
            key: "sceneName",
          },
          {
            title: "施用量",
            key: "amount",
            render: (h, { row }) => {
              return <span>{row.amount}kg</span>;
            },
          },
          {
            title: "施用时间",
            key: "applyTime",
            render: (h, { row }) => {
              let str = row.applyTime ? row.applyTime.substring(0, 10) : "";
              return <span>{str}</span>;
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      form: {
        id: "",
        farmId: "",
        sceneId: "",
        amount: "",
        applyTime: "",
        productId: "",
        productName: "",
        productIds: "",
      },
      rules: {
        farmId: [{ required: true, message: "请选择施用基地" }],
        sceneId: [{ required: true, message: "请选择施用地块" }],
        amount: [{ required: true, message: "请填写施用量" }],
        applyTime: [{ required: true, message: "请选择施用时间" }],
        productIds: [{ required: true, message: "请选择施用时间" }],
      },
      farms: [],
      blocks: [],
      category: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "施用上报",
            ca: "",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "施用基地",
              component: "select",
              field: "farmId",
              defaultValue: "",
              data: this.farms,
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    getList() {
      // if (!this.CA("")) return;
      this.table.loading = true;
      this.$post(this.$api.WASTE_APPLICATION.PLANT_LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    // 获取基地列表
    getFarms() {
      return new Promise((resolve) => {
        this.$post(this.$api.BASE_MANAGE.SIMPLE_FARM_LIST, {
          pageNo: 1,
          pageSize: 99999,
          siteType: "1",
        }).then((res) => {
          this.farms = res.list;
          let list = res.list.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          resolve(list);
        });
      });
    },
    // 获取地块列表
    getBlock(farmId) {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCTION_AREA.LIST, {
          pageNo: 1,
          pageSize: 9999,
          farmId,
          siteType: "1",
        }).then((res) => {
          this.blocks = res.list;
          let list = res.list.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          resolve(list);
        });
      });
    },
    getCategory() {
      this.$post(this.$api.USER_PRODUCT.LIST, {
        type: 3,
      }).then((res) => {
        this.category = res.list;
      });
    },
    search(value) {
      this.search_data = value;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "施用上报",
        submitLoading: false,
      };
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        let params = { ...this.form };
        params.productId = this.form.productIds.split("/")[0];
        params.productName = this.form.productIds.split("/")[1];
        params.applyTime = moment(this.form.applyTime).format("YYYY-MM-DD");
        this.$post(this.$api.WASTE_APPLICATION.PLANT_ADD, params).then(() => {
          this.$Message.success("上报成功");
          this.getList();
          this.modal.show = false;
        });
      });
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (column.key == "amount") {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    modalChange(visible) {
      if (visible) return;
      this.form = {
        id: "",
        farmId: "",
        sceneId: "",
        amount: "",
        applyTime: "",
        productId: "",
        productName: "",
        productIds: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
    this.getFarms();
    this.getCategory();
  },
  watch: {
    "form.farmId"(val) {
      this.getBlock(val);
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>